import { fromJS } from "immutable"
import { createAction } from "./utils"

export const types = {
  BUY_SONG_LICENSE: "soundstripe/interactions/BUY_SONG_LICENSE",
}

export const actions = {
  buySongLicense: createAction(types.BUY_SONG_LICENSE, "song"),
}

const initialState = fromJS({})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
