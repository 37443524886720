import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { color } from "styled-system"

const SvgIcon = styled.svg`
  ${color}
  display: inline-block;
  fill: ${(props) => props.fill || "currentColor"};

  @media (hover: hover) {
    &:hover {
      color: ${(props) =>
        props.hoverColor || props.color || props.theme.colors.text.primary};
    }
  }

  &.active {
    &:active {
      opacity: 0.6;
    }
  }
  &.thick {
    stroke-width: 4px !important;
  }
`

const Icon = (props) => {
  const className = classNames(
    { pointer: props.onClick },
    { active: props.onClick },
    props.className
  )
  return (
    <SvgIcon
      className={className}
      data-cy={props["data-cy"]}
      data-cy-info={props["data-cy-info"]}
      color={props.color}
      height={props.size}
      hoverColor={props.hoverColor}
      onClick={props.onClick}
      preserveAspectRatio="xMidYMid meet"
      style={props.style}
      viewBox={props.icon[1]}
      width={props.size}
    >
      <g>
        <path
          stroke={props.pathStroke}
          d={props.icon[0]}
          fill={props.fill}
          fillRule={props.fillRule}
          clipRule={props.clipRule}
        />
      </g>
    </SvgIcon>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  "data-cy": PropTypes.string,
  hoverColor: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  pathStroke: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  fill: PropTypes.string,
  fillRule: PropTypes.string,
  clipRule: PropTypes.string,
}

Icon.defaultProps = {
  pathStroke: "none",
  color: "text.secondary",
  size: 16,
  style: {},
}

export default Icon
