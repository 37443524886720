import { delay, select, put, take, race, takeEvery } from "redux-saga/effects"
import { actions as modalActions } from "ducks/modal"
import {
  types as currentUserTypes,
  selectCurrentUserRecord,
} from "ducks/currentUser"
import { types as interactionTypes } from "ducks/interactions"
import { types as modalTypes } from "ducks/modal"

export function* buySongLicense(action) {
  try {
    const user = yield select(selectCurrentUserRecord())

    if (!user.id) {
      yield put(modalActions.open("SignUpModal", { action: "license" }))

      const { cancelled } = yield race({
        authenticate: take(currentUserTypes.REQUEST_SUCCESS), // technically this should be `AUTHENTICATED` or something like that
        cancelled: take(modalTypes.CLOSE),
      })

      if (cancelled) return
    }

    // just wait for all the other random stuff that happens post sign-in to finish. when side effects are not called
    // haphazardly from components, we won't need this
    yield delay(300)

    yield put(
      modalActions.open("SingleSongLicenseModal", { songs: [action.song] })
    )
  } catch (err) {
    console.error(err)
  }
}

export function* watchBuySongLicense() {
  yield takeEvery(interactionTypes.BUY_SONG_LICENSE, buySongLicense)
}

export default [watchBuySongLicense]
