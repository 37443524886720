import { fromJS } from "immutable"
import { createAction } from "./utils"
import { createSelector } from "reselect"

export const types = {
  IDENTIFY_CURRENT_USER: "soundstripe/tracking/IDENTIFY_CURRENT_USER",
  IDENTIFY_CURRENT_USER_SUCCESS:
    "soundstripe/tracking/IDENTIFY_CURRENT_USER_SUCCESS",
  UNIDENTIFY_CURRENT_USER: "soundstripe/tracking/UNIDENTIFY_CURRENT_USER",

  TRACK_MIXPANEL: "soundstripe/tracking/TRACK_MIXPANEL",
  TRACK_LINKEDIN: "soundstripe/tracking/TRACK_LINKEDIN",
  TRACK_BING: "soundstripe/tracking/TRACK_BING",
  TRACK_GOOGLE_ADS: "soundstripe/tracking/TRACK_GOOGLE_ADS",
  TRACK_TIKTOK: "soundstripe/tracking/TRACK_TIKTOK",
  TRACK_USERPILOT: "soundstripe/tracking/TRACK_USERPILOT",

  TRACK_ENTERPRISE_FORM_SUBMIT:
    "soundstripe/tracking/TRACK_ENTERPRISE_FORM_SUBMIT",
  TRACK_MARKETPLACE_PURCHASE: "soundstripe/tracking/TRACK_MARKETPLACE_PURCHASE",
  TRACK_CART_PURCHASE: "soundstripe/tracking/TRACK_CART_PURCHASE",
  TRACK_QUALIFIED_USER: "soundstripe/tracking/TRACK_QUALIFIED_USER",
  TRACK_SIGN_IN: "soundstripe/tracking/TRACK_SIGN_IN",
  TRACK_GOOGLE_OAUTH_SIGN_IN: "soundstripe/tracking/TRACK_GOOGLE_OAUTH_SIGN_IN",
  TRACK_GOOGLE_OAUTH_SIGN_UP: "soundstripe/tracking/TRACK_GOOGLE_OAUTH_SIGN_UP",
}

export const actions = {
  identifyCurrentUser: createAction(types.IDENTIFY_CURRENT_USER),
  identifyCurrentUserSuccess: createAction(types.IDENTIFY_CURRENT_USER_SUCCESS),
  unidentifyCurrentUser: createAction(types.UNIDENTIFY_CURRENT_USER),

  trackMixpanel: createAction(
    types.TRACK_MIXPANEL,
    "eventName",
    "eventProperties"
  ),
  trackLinkedIn: createAction(types.TRACK_LINKEDIN, "event", "data"),
  trackBing: createAction(types.TRACK_BING, "action", "params"),
  trackGoogleAds: createAction(types.TRACK_GOOGLE_ADS, "event", "data"),
  trackTikTok: createAction(types.TRACK_TIKTOK, "event", "data"),
  trackUserpilot: createAction(types.TRACK_USERPILOT, "event", "data"),

  trackEnterpriseFormSubmit: createAction(types.TRACK_ENTERPRISE_FORM_SUBMIT),
  trackMarketplacePurchase: createAction(
    types.TRACK_MARKETPLACE_PURCHASE,
    "meta"
  ),
  trackCartPurchase: createAction(types.TRACK_CART_PURCHASE, "response"),
  trackQualifiedUser: createAction(types.TRACK_QUALIFIED_USER),
  trackSignIn: createAction(types.TRACK_SIGN_IN, "mixpanelParams"),
  trackGoogleOauthSignIn: createAction(
    types.TRACK_GOOGLE_OAUTH_SIGN_IN,
    "mixpanelParams"
  ),
  trackGoogleOauthSignUp: createAction(
    types.TRACK_GOOGLE_OAUTH_SIGN_UP,
    "mixpanelParams"
  ),
}

const initialState = fromJS({
  identified: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.IDENTIFY_CURRENT_USER_SUCCESS:
      return state.set("identified", true)
    case types.UNIDENTIFY_CURRENT_USER:
      return state.set("identified", false)
    default:
      return state
  }
}

const selectTrackingState = (state) => state.getIn(["ducks", "tracking"])

export const selectIdentified = () =>
  createSelector(selectTrackingState, (trackingState) =>
    trackingState.get("identified")
  )
