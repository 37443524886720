import React from "react"
import Box from "components/Box"
import Button from "components/Button/New"
import { withTheme } from "styled-components"
import { useDispatch } from "react-redux"
import { actions as modalActions } from "ducks/modal"
import { actions as favoritableActions } from "ducks/favoritable"
import useCurrentUser from "hooks/useCurrentUser"
import { loggedIn } from "utils/authentication"
import LicenseButton from "./LicenseButton"
import useMarketplaceMixpanelTracking from "hooks/useMarketplaceMixpanelTracking"
import DottedButton from "components/DottedButton"
import MarketplaceSong from "blocks/MarketplaceSong"

import {
  ICON_SS_HEART_FULL,
  ICON_SS_HEART_EMPTY,
} from "components/Icon/constants"

const SongActions = ({
  isMobile,
  condensedLayout,
  song,
  theme,
  removeOnUnfavorite,
  openDottedButtonModal,
}) => {
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()
  const { trackMarketplaceMixpanel } = useMarketplaceMixpanelTracking()

  const favorite = () => {
    trackMarketplaceMixpanel("Favorited Song", {
      "Song ID": song.id,
      "Song Title": song.title,
      "Content Type": song.contentType(),
    })
    dispatch(favoritableActions.favorite(song))
  }

  const unfavorite = () => {
    trackMarketplaceMixpanel("Unfavorited Song", {
      "Song ID": song.id,
      "Song Title": song.title,
      "Content Type": song.contentType(),
    })
    dispatch(
      favoritableActions.unfavorite(
        song,
        removeOnUnfavorite && "favoriteMarketplaceSongs"
      )
    )
  }

  const openSignUpModal = (data) => {
    dispatch(modalActions.open("SignUpModal", data))
  }

  const handleAddToFavorites = () => {
    if (loggedIn()) {
      if (currentUser.favoritedMarketplaceSong(song.id)) {
        unfavorite()
      } else {
        favorite()
      }
      return
    }

    return openSignUpModal({
      action: "marketplace_favorite",
      afterSignup: currentUser.favoritedMarketplaceSong(song.id)
        ? favorite
        : null,
    })
  }

  const handleDottedBtnClick = (event) => {
    event.preventDefault()
    openDottedButtonModal()
  }

  return (
    <>
      {isMobile ? (
        <MarketplaceSong.DottedButton onClick={handleDottedBtnClick}>
          <DottedButton />
        </MarketplaceSong.DottedButton>
      ) : (
        <>
          <Box
            paddingRight="15px"
            display={condensedLayout ? { _: "none", lg: "block" } : "block"}
          >
            <Box
              data-cy="favorite-song-button"
              data-tooltip={
                currentUser.favoritedMarketplaceSong(song.id)
                  ? "Remove from Favorites"
                  : "Add To Favorites"
              }
            >
              <Button
                icon={
                  currentUser.favoritedMarketplaceSong(song.id)
                    ? ICON_SS_HEART_FULL
                    : ICON_SS_HEART_EMPTY
                }
                color={
                  currentUser.favoritedMarketplaceSong(song.id)
                    ? theme.colors.text.accent
                    : undefined
                }
                data-cy="mktplace-song-favorite-btn"
                data-cy-info={
                  currentUser.favoritedMarketplaceSong(song.id)
                    ? "remove"
                    : "add"
                }
                onClick={handleAddToFavorites}
                aria-label="Add song to favorites"
                appearance="subtle"
              />
            </Box>
          </Box>
          <Box as="span" display="block">
            <LicenseButton song={song} />
          </Box>
        </>
      )}
    </>
  )
}

export default withTheme(SongActions)
