import PropTypes from "prop-types"
import React, { memo } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { MarketplaceSong as MarketplaceSongRecord } from "records"
import { actions as marketplaceAudioPlayerActions } from "ducks/marketplaceAudioPlayer"
import { actions as modalActions } from "ducks/modal"
import useMarketplaceMixpanelTracking from "hooks/useMarketplaceMixpanelTracking"
import { loggedIn } from "utils/authentication"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background.layered};
  box-shadow: ${(props) => props.theme.shadows.dropdown};
  width: 100%;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 500;
  border-bottom: 2px solid ${(props) => props.theme.audioPlayer.borderColor};
  border-top-left-radius: 5px;
`

const Option = styled.div`
  cursor: pointer;
  border: 2px transparent;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.primary};
  vertical-align: middle;
  line-height: 50px;
  padding-left: 1rem;
  transition: all ${(props) => props.theme.transitions.quick};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: ${(props) => props.theme.colors.background.elevationLow};
    text-decoration: underline;
  }
`

const MorePopover = ({ song }) => {
  const dispatch = useDispatch()
  const { trackMarketplaceMixpanel } = useMarketplaceMixpanelTracking()

  const openSignUpModal = (data) => {
    dispatch(modalActions.open("SignUpModal", data))
  }

  const openLicenseModal = () => {
    const slideout = song.popular()
      ? "MultiDistributionLicenseSlideout"
      : "MarketTieredPricingSlideout"

    dispatch(modalActions.open(slideout, { song: song }))
  }

  const handleLicenseSong = () => {
    trackMarketplaceMixpanel("Clicked Element", {
      context: "License Song",
      "Song ID": song.id,
      "Song Title": song.title,
      "Content Type": song.contentType(),
    })

    loggedIn()
      ? openLicenseModal()
      : openSignUpModal({
          action: "marketplace_license",
          afterSignup: openLicenseModal,
        })
  }

  const handleClosePlayer = () => {
    dispatch(marketplaceAudioPlayerActions.stop())
  }

  return (
    <Wrapper>
      <Option onClick={handleLicenseSong}>License</Option>
      <Option onClick={handleClosePlayer}>Close Player</Option>
    </Wrapper>
  )
}

MorePopover.propTypes = {
  song: PropTypes.instanceOf(MarketplaceSongRecord).isRequired,
}

export default memo(MorePopover)
