import PropTypes from "prop-types"
import React, { useEffect } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { selectPlayerVisible } from "ducks/audioPlayer"
import { selectPlayerVisible as selectMarketplacePlayerVisible } from "ducks/marketplaceAudioPlayer"
import { selectAppBannerVisible } from "ducks/ui"
import { actions as currentUserActions } from "ducks/currentUser"
import { actions as cookieActions } from "middlewares/cookies"
import { showCrispButton, hideCrispButton } from "utils/crisp"
import { onTrackingLoaded } from "utils/tracking"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import useCurrentUser from "hooks/useCurrentUser"
import { loggedIn } from "utils/authentication"
import PageContentWrapper from "./PageContentWrapper"
import { Userpilot } from "userpilot"

const PageContent = ({
  playerVisible,
  marketplacePlayerVisible,
  appBannerVisible,
  className,
  location,
  children,
  id,
  updateUser,
  getCookie,
}) => {
  const { currentUser } = useCurrentUser()
  const { trackMixpanel } = useMixpanelTracking()

  useEffect(() => {
    const path = location.pathname
    const isPricingPage =
      path.includes("/pricing") || path.includes("/business-pricing")

    // For pricing pages, reinitialize Crisp
    if (isPricingPage && !playerVisible && !marketplacePlayerVisible) {
      if (window.$crisp) {
        window.$crisp.push(["do", "chat:show"])
      }
      return
    }

    // Handle non-pricing pages
    if (loggedIn()) {
      hideCrispButton()
    } else if (!playerVisible && !marketplacePlayerVisible) {
      if (!currentUser.subscription) {
        showCrispButton()
      } else {
        hideCrispButton()
      }
    } else {
      hideCrispButton()
    }
  }, [location.pathname, playerVisible, marketplacePlayerVisible, currentUser])

  useEffect(() => {
    checkCookieYes()
    Userpilot.reload()
    trackMixpanel("Viewed Page")
    onTrackingLoaded(() => mixpanel?.track_pageview())
  }, [location.pathname])

  const checkCookieYes = () => {
    const cookieYesConsent = getCookie("cookieyes-consent")

    if (!cookieYesConsent) {
      return
    }
    if (currentUser.cookie_yes_consent === cookieYesConsent) {
      return
    }
    if (loggedIn()) {
      updateUser({
        data: {
          attributes: {
            cookie_yes_consent: cookieYesConsent,
          },
        },
      })
    }
  }

  return (
    <PageContentWrapper
      className={classNames(className, {
        playerVisible,
        appBannerVisible,
      })}
      id={id}
    >
      {children}
    </PageContentWrapper>
  )
}

PageContent.propTypes = {
  children: PropTypes.node,
  appBannerVisible: PropTypes.bool,
  playerVisible: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
}

const mapStateToProps = (state) => ({
  playerVisible: selectPlayerVisible()(state),
  marketplacePlayerVisible: selectMarketplacePlayerVisible()(state),
  appBannerVisible: selectAppBannerVisible()(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(currentUserActions.update(data)),
  getCookie: (key) => dispatch(cookieActions.getCookie(key)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageContent))
