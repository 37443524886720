import PropTypes from "prop-types"
import React from "react"
import Logo from "components/Logo"
import { push } from "react-router-redux"
import { FormattedMessage } from "react-intl"
import { openLiveChat } from "utils/crisp"
import { connect, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router"
import styled from "styled-components"
import classNames from "classnames"
import { User as UserRecord } from "records"
import { loggedIn } from "utils/authentication"
import { actions as uiActions, selectAppBannerVisible } from "ducks/ui"
import Box from "components/Box"
import { Text } from "components/Typography"
import Button from "components/Button/New"
import Container from "components/Container"
import { actions as modalActions } from "ducks/modal"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import useOffer from "hooks/useOffer"
import AuthSection from "./AuthSection"
import UserInfoSection from "./UserInfoSection"
import SkipOnboardingStepLink from "./SkipOnboardingStepLink"
import LogoImg from "./LogoImg"
import NavLinks from "./NavLinks"
import SignUpBtn from "./SignUpBtn"
import MyMediaMenu from "./MyMediaMenu"
import linkStyles from "./linkStyles"
import FilterButton from "./FilterButton"
import MobileMenuButton from "./MobileMenuButton"
import MobileSideBarMenu from "./MobileSideBarMenu"
import useBreakpoints from "hooks/useBreakpoints"
import useMarketplaceMixpanelTracking from "hooks/useMarketplaceMixpanelTracking"
import {
  ICON_SS_CART_AVATAR,
  ICON_SS_CART_NEW,
  ICON_SS_CART_FILLED,
} from "components/Icon/constants"
import SoundstripeIcon from "components/Icons/SoundstripeIcon"
import useFlag from "hooks/useFlag"
import { selectCartItems } from "ducks/cart"

const StyledLink = styled(Button)`
  ${linkStyles};
`

const StoreLink = styled(Button)`
  ${linkStyles}

  &:hover {
    color: ${({ theme }) => theme.colors.brand.pinkJoy};
  }
`

const Wrapper = styled.header`
  background: ${({ theme }) => theme.colors.background.secondary};
  border-bottom: ${({ noScroll, theme }) =>
    noScroll ? "none" : `${theme.borders[0]} ${theme.colors.border.subtle}`};
  display: none;
  height: ${({ theme }) => theme.sizes.elements.header.height.desktop};
  position: ${({ noScroll }) => (noScroll ? "relative" : "sticky")};
  top: 0;
  width: ${({ theme }) => theme.sizes.container.full};
  z-index: ${({ theme }) => theme.zIndices.navbar};

  &.appBannerVisible {
    top: ${({ theme }) => theme.sizes.elements.appBanner.height.desktop};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`

const MobileHeaderWrapper = styled.header`
  align-items: center;
  background: ${({ theme }) => theme.colors.background.secondary};
  border-bottom: ${({ noScroll, theme }) =>
    noScroll ? "none" : `${theme.borders[0]} ${theme.colors.border.subtle}`};
  display: flex;
  height: ${({ theme }) => theme.sizes.elements.header.height.desktop};
  justify-content: space-between;
  position: ${({ noScroll }) => (noScroll ? "relative" : "sticky")};
  top: 0;
  width: ${({ theme }) => theme.sizes.container.full};
  z-index: ${({ theme }) => theme.zIndices.popup};

  &.appBannerVisible {
    top: ${({ theme }) => theme.sizes.elements.appBanner.height.mobile};
  }

  @media print {
    display: none;
  }
`

const NavContainer = styled.nav`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: ${({ logoOnly }) => (logoOnly ? "center" : "space-between")};
  width: ${({ logoOnly }) => (logoOnly ? "100%" : "auto")};
`

const CartCount = styled(Box)`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 6.5px 7px 7.5px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--background-status-info, #2767e7);

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 6px 7px 8px 7px;
  }
`

const MobileLogo = () => {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(uiActions.closeMobileSidebar())
  }
  return (
    <Link to="/" aria-label="soundstripe.com" onClick={onClick}>
      <Logo width="auto" height="24px" />
    </Link>
  )
}

const Header = ({
  appBannerVisible,
  currentUser,
  cartItems,
  signUpMode,
  singleSongCheckoutMode,
  userInfoPageActive,
  logoOnly,
  isMobile,
}) => {
  const { isSm, isMd } = useBreakpoints()
  const dispatch = useDispatch()
  const offer = useOffer()
  const { trackMixpanel } = useMixpanelTracking()
  const { trackMarketplaceMixpanel } = useMarketplaceMixpanelTracking()
  const { enabled: newAvatarMenu } = useFlag("new_avatar_menu")
  const totalCartItems = cartItems.size

  const toggleSidebarMenu = () => {
    dispatch(uiActions.toggleMobileSidebar())
  }

  const upgradeModalOpen = () => {
    dispatch(modalActions.open("UpgradeModal", { context: "Header Button" }))
  }

  const goToPricing = () => {
    dispatch(push("/pricing"))
  }

  const goToStore = () => {
    dispatch(push("/market"))
    trackMarketplaceMixpanel("Clicked Element", {
      Context: "Marketplace Link - Header",
    })
  }

  const goToMobileApp = () => {
    trackMixpanel("Clicked Mobile App Link", {
      Context: "Header Link",
      "Link Text": "Mobile App",
    })
    dispatch(push("/mobile-app"))
  }

  const openCart = () => {
    trackMixpanel("Clicked Element", { context: "Header Nav", element: "Cart" })
    dispatch(modalActions.open("CartSlideout"))
  }

  const wrapperClassName = classNames({ appBannerVisible })
  const pricingPageActive = window && window.location.pathname === "/pricing"
  const showSimpleNav = pricingPageActive
  const offerButtonText =
    isSm && offer ? "Sale" : offer ? offer.upgradeCallout.header : "Upgrade"
  const showMobileAppLink = isSm
    ? false
    : !loggedIn() || signUpMode || singleSongCheckoutMode

  if (isMobile || isSm) {
    if (logoOnly) {
      return (
        <MobileHeaderWrapper noScroll={true}>
          <Box display="flex" width="100%" justifyContent="center">
            <MobileLogo />
          </Box>
          <MobileSideBarMenu />
        </MobileHeaderWrapper>
      )
    }

    if (userInfoPageActive) {
      return (
        <MobileHeaderWrapper className={wrapperClassName}>
          <Box width="80px" />
          <MobileLogo />
          <SkipOnboardingStepLink />
          <MobileSideBarMenu />
        </MobileHeaderWrapper>
      )
    }

    return (
      <MobileHeaderWrapper className={wrapperClassName}>
        <MobileMenuButton
          onClick={toggleSidebarMenu}
          data-cy="mobile-menu-toggle"
        />
        <MobileLogo />
        {!currentUser.hasSubscription() && totalCartItems > 0 && (
          <Box alignItems="center" right={9} position="absolute" display="flex">
            <Button
              appearance="ghost"
              icon={ICON_SS_CART_NEW}
              iconAlign="center"
              iconSize={24}
              onClick={openCart}
            ></Button>
            <CartCount mt="1.6rem" right="0rem" position="absolute">
              <Text fontWeight="semiBold" fontSize="8px" color="white">
                {totalCartItems}
              </Text>
            </CartCount>
          </Box>
        )}
        <Box p={5}>
          <FilterButton isSm={isSm} />
        </Box>
        <MobileSideBarMenu />
      </MobileHeaderWrapper>
    )
  }

  if (userInfoPageActive) {
    return (
      <Wrapper role="navigation" className={wrapperClassName}>
        <Container className="header">
          <NavContainer>
            <Box width="96px" />
            <LogoImg className="center" />
            <SkipOnboardingStepLink />
          </NavContainer>
        </Container>
      </Wrapper>
    )
  }

  if (logoOnly || showSimpleNav) {
    return (
      <Wrapper role="navigation" noScroll={true}>
        <Container className="header">
          <NavContainer logoOnly>
            <LogoImg className="center" mr={{ _: 0 }} />
          </NavContainer>
        </Container>
      </Wrapper>
    )
  }

  return (
    <Wrapper role="navigation" className={wrapperClassName}>
      <Container className="header">
        <NavContainer logoOnly={logoOnly && !userInfoPageActive}>
          <Box alignItems="center" display="flex" height="100%">
            {loggedIn() && isMd ? <SoundstripeIcon /> : <LogoImg />}
            <NavLinks />
          </Box>
          <Box alignItems="center" display="flex" height="100%">
            {loggedIn() && !signUpMode && !currentUser.subscription && (
              <Box px={{ _: 2, md: 3 }}>
                <SignUpBtn
                  data-cy="nav-signup-btn"
                  className="btn btn-md btn-pink less-shadow"
                />
              </Box>
            )}
            {!newAvatarMenu && (
              <StoreLink
                className="link-pinkJoy"
                onClick={goToStore}
                icon={ICON_SS_CART_FILLED}
                iconSize={20}
              >
                Market
                <Box
                  display="inline-block"
                  color="pink400"
                  fontSize="xs"
                  borderRadius="default"
                  p={1}
                  backgroundColor="pink000"
                  ml={2}
                  lineHeight="locked"
                >
                  Beta
                </Box>
              </StoreLink>
            )}
            {showMobileAppLink && (
              <StyledLink onClick={goToMobileApp}>Mobile App</StyledLink>
            )}
            {(!loggedIn() || signUpMode || singleSongCheckoutMode) && (
              <StyledLink onClick={openLiveChat}>
                <FormattedMessage
                  id="link.liveChat"
                  defaultMessage="Live Chat"
                />
              </StyledLink>
            )}
            {(!loggedIn() || currentUser.free_account) && (
              <Box display={{ _: "none", md: "block" }}>
                <StyledLink data-cy="nav-pricing-link" onClick={goToPricing}>
                  Pricing
                </StyledLink>
              </Box>
            )}
            {!newAvatarMenu && loggedIn() && (
              <Box height="100%">
                <MyMediaMenu currentUser={currentUser} />
              </Box>
            )}
            {loggedIn() ? (
              currentUser.id && <UserInfoSection currentUser={currentUser} />
            ) : (
              <AuthSection signUpMode={signUpMode} />
            )}

            {loggedIn() && currentUser.isUpgradeEligible() && (
              <Box ml={1}>
                <Button
                  appearance="pinkJoy"
                  data-cy="nav-upgrade-btn"
                  onClick={upgradeModalOpen}
                  fontSize="sm"
                >
                  {offerButtonText || "Upgrade"}
                </Button>
              </Box>
            )}
            {!currentUser.hasSubscription() && totalCartItems > 0 && (
              <Box display="flex">
                <Button
                  appearance="ghost"
                  icon={ICON_SS_CART_AVATAR}
                  iconAlign="center"
                  iconSize={40}
                  onClick={openCart}
                ></Button>
                <CartCount right="27px" bottom="10.5px" position="absolute">
                  <Text fontWeight="semiBold" fontSize="9px" color="white">
                    {totalCartItems}
                  </Text>
                </CartCount>
              </Box>
            )}
          </Box>
        </NavContainer>
      </Container>
    </Wrapper>
  )
}

Header.propTypes = {
  appBannerVisible: PropTypes.bool,
  currentUser: PropTypes.instanceOf(UserRecord).isRequired,
  signUpMode: PropTypes.bool,
  singleSongCheckoutMode: PropTypes.bool,
  logoOnly: PropTypes.bool,
  userInfoPageActive: PropTypes.bool,
  isMobile: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  appBannerVisible: selectAppBannerVisible()(state),
  cartItems: selectCartItems()(state),
})

export default withRouter(connect(mapStateToProps)(Header))
