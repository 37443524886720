const trackMixpanelMarketplaceMusicSearch = (searchState) => {
  const { mood, characteristic, instrument, genre, energy } = searchState
  const searchQuery = searchState.searchQuery || ""
  const sort = searchState.sort || ""

  const activeEnergy =
    (energy?.activeFilters &&
      energy.activeFilters.map((e) => e.toLowerCase().replace(/\s/g, "_"))) ||
    []

  const activeCharacteristics = characteristic?.activeFilters || []
  const activeGenres = genre?.activeFilters || []
  const activeInstruments = instrument?.activeFilters || []
  const activeMoods = mood?.activeFilters || []

  const activeTags = activeEnergy.concat(
    activeCharacteristics,
    activeGenres,
    activeInstruments,
    activeMoods
  )

  const properties = {
    marketplace: true,
    Query: searchQuery,
    Tags: activeTags,
    Sort:
      sort === "approved_at"
        ? "order_most_recently_approved"
        : "order_most_popular",
  }

  return properties
}

export default trackMixpanelMarketplaceMusicSearch
