import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  spawn,
  delay,
} from "redux-saga/effects"
import { push } from "react-router-redux"
import {
  startSubmit,
  stopSubmit,
  reset,
  destroy,
  initialize,
} from "redux-form/immutable"
import queryString from "query-string-parser"
import Cookie from "js-cookie"
import {
  get,
  post,
  patch,
  prependApiUrl,
  defaultHeaders,
  registrationDigestHeader,
  _delete,
} from "utils/request"
import {
  updateMixpanelPeopleProperties,
  updateMixpanelSuperProperties,
  UTM_PARAMS,
  trackUserpilot,
} from "utils/tracking"
import { isImmutable } from "immutable"
import { uploadFileInChunks } from "utils/fileUpload"
import { initAskNicely, initUserPilot } from "utils/thirdPartyIntegrations"
import { actions as uiActions } from "ducks/ui"
import { actions as modalActions } from "ducks/modal"
import { actions as trackingActions } from "ducks/tracking"
import { actions as cartActions, selectInvoiceEstimate } from "ducks/cart"
import { selectIsFlagEnabled } from "ducks/flag"
import { FORM as SIGN_IN_FORM } from "pages/SignInPage/constants"
import {
  getTokenPayload,
  currentUserIdFromToken,
  storeToken,
  removeToken,
  loggedIn,
  authTokenExpired,
} from "utils/authentication"
import { isProduction } from "utils/environment"
import { formatDate } from "utils/dateUtils"
import {
  types,
  actions,
  selectCurrentUserRecord,
  selectCurrentUserPaymentIntent,
  selectCurrentUserEstimate,
  selectCurrentUserPaymentSource,
} from "ducks/currentUser"
import {
  actions as referralActions,
  selectReferringUserId,
} from "ducks/referral"
import { actions as cookieActions } from "middlewares/cookies"
import {
  actions as subscriptionActions,
  types as subscriptionTypes,
} from "ducks/subscription"
import {
  types as checkoutTypes,
  actions as checkoutActions,
} from "ducks/checkout"
import isEmpty from "lodash/isEmpty"
import { User as UserRecord } from "records"
import { makeRecord } from "records/util"
import { setLiveChatUserInfo } from "ducks/utils/currentUser"
import { identifyBing, trackBingEvent } from "utils/conversionTracking"
import { debugFlag } from "utils/debugFlag"
import merge from "lodash/merge"
import { googleOauth } from "utils/googleOauth"
import { identifyCurrentUser } from "sagas/tracking"

const mergeUseWithGeoHeaders = (
  data,
  headers = { "client-geo-country": "" }
) => {
  return merge(data, {
    data: { attributes: { geoCountry: headers["client-geo-country"] } },
  })
}

export function* load() {
  try {
    const tokenPayload = getTokenPayload()
    const userId = tokenPayload.sub
    const requestURL = prependApiUrl(`users/${userId}`)
    const { data, headers } = yield call(get, requestURL, { hasHeaders: true })
    yield put(actions.requestSuccess(mergeUseWithGeoHeaders(data, headers)))
    const currentUser = yield select(selectCurrentUserRecord())
    yield call(setLiveChatUserInfo, currentUser)
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchLoad() {
  yield takeLatest(types.LOAD, load)
}

export function* update(action) {
  const FORM = action.form || null
  if (FORM) {
    yield put(startSubmit(FORM))
  }
  try {
    const tokenPayload = getTokenPayload()
    const userId = tokenPayload.sub
    const requestURL = prependApiUrl(`users/${userId}`)
    const data = isImmutable(action.data) ? action.data.toJS() : action.data
    data.data.id = userId.toString()
    data.data.type = "users"

    const options = { data: JSON.stringify(data) }
    const response = yield call(patch, requestURL, options)
    yield put(actions.requestSuccess(response))

    const user = yield select(selectCurrentUserRecord())
    updateMixpanelPeopleProperties(user)

    if (action.redirectPath) {
      yield put(push(action.redirectPath))
    }

    if (FORM) {
      yield put(stopSubmit(FORM))
      yield put(reset(FORM))
    }

    if (FORM && FORM === "pre_sign_up_form") {
      const userRecord = yield select(selectCurrentUserRecord())
      if (userRecord.canManageTeam()) {
        yield put(
          modalActions.open("InviteAccountUsersModal", { signUp: true })
        )
      } else {
        // yield put(modalActions.open("ReferralInviteModal"))
      }
    }
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
    if (FORM) {
      yield put(stopSubmit(FORM, { _error: err.message }))
    }
  }
}

export function* watchUpdate() {
  yield takeEvery(types.UPDATE, update)
}

export function* signIn(action) {
  yield put(startSubmit(SIGN_IN_FORM))
  const requestURL = `${process.env.API_URL}/sessions`
  const marketPage = window.location.pathname.includes("market")
  try {
    const requestData = {
      data: {
        type: "sessions",
        attributes: action.values.toJS(),
      },
    }
    const options = {
      data: JSON.stringify(requestData),
      hasHeaders: true,
    }
    const { data, headers } = yield call(post, requestURL, options)
    if (data.error) {
      const error = { message: data.error.message }
      throw error
    }
    const session = data.data
    const authToken = session.attributes.jwt
    const _included = merge(data.included[0], {
      attributes: { geoCountry: headers["client-geo-country"] },
    })
    data.included = [_included]
    yield put(actions.signInSuccess(data, authToken))
    yield put(stopSubmit(SIGN_IN_FORM))
    yield put(reset(SIGN_IN_FORM))

    const user = yield select(selectCurrentUserRecord())
    yield call(updateMixpanelSuperProperties, user)
    yield call(identifyCurrentUser)

    yield put(
      trackingActions.trackMixpanel("Signed In", {
        Context: action.tracking.context,
        marketplace: marketPage,
      })
    )

    if (action.redirectPath) {
      yield put(push(action.redirectPath))
    } else {
      yield put(modalActions.close())
    }

    if (action.mobilePlayerObject && !isEmpty(action.mobilePlayerObject)) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobilePlayerObject)
      )
    }

    yield spawn(initThirdPartyTracking, "Signed In")
    if (action.callback) yield action.callback()
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(SIGN_IN_FORM, { _error: err.message }))
  }
}

export function* newSignIn(action) {
  yield put(startSubmit(SIGN_IN_FORM))

  try {
    const requestURL = `${process.env.API_URL}/sessions`

    const requestData = {
      data: {
        type: "sessions",
        attributes: action.values.toJS(),
      },
    }

    const options = {
      data: JSON.stringify(requestData),
      hasHeaders: true,
    }

    const { data, headers } = yield call(post, requestURL, options)

    if (data.error) {
      const error = { message: data.error.message }
      throw error
    }

    const session = data.data
    const authToken = session.attributes.jwt

    const _included = merge(data.included[0], {
      attributes: { geoCountry: headers["client-geo-country"] },
    })

    data.included = [_included]
    yield put(actions.signInSuccess(data, authToken))
    yield put(stopSubmit(SIGN_IN_FORM))
    yield put(reset(SIGN_IN_FORM))

    yield put(
      trackingActions.trackSignIn({
        Context: action.tracking.context,
        marketplace: window.location.pathname.includes("market"),
      })
    )

    if (action.redirectPath) {
      yield put(push(action.redirectPath))
    } else {
      yield put(modalActions.close())
    }

    if (action.mobilePlayerObject && !isEmpty(action.mobilePlayerObject)) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobilePlayerObject)
      )
    }

    if (action.callback) yield action.callback()
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(SIGN_IN_FORM, { _error: err.message }))
  }
}

export function* watchSignIn() {
  yield takeLatest(types.SIGN_IN_SUBMIT, function*(action) {
    const flagEnabled = yield select(selectIsFlagEnabled("new_sign_in_saga"))

    if (flagEnabled) {
      yield call(newSignIn, action)
    } else {
      yield call(signIn, action)
    }
  })
}

export function* signInSuccess() {
  const user = yield select(selectCurrentUserRecord())

  const openCompanyModal =
    user.owner() && user.requires_company && !user.company_name

  if (openCompanyModal) yield put(modalActions.open("UpdateCompanyModal"))
}

export function* watchSignInSuccess() {
  yield takeLatest(types.SIGN_IN_SUCCESS, signInSuccess)
}

export function* signUp(action) {
  const signUpForm = action.form
  const marketPage = window.location.pathname.includes("market")

  yield put(startSubmit(signUpForm))
  try {
    const referring_user_id = yield select(selectReferringUserId())
    const userAttributes = action.values.toJS()
    const { accountInviteToken, signupSelectedPlan } = action

    const requestPath = accountInviteToken
      ? "account_users"
      : "secure_user_registrations"
    const requestURL = `${process.env.API_URL}/${requestPath}`
    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const cookieYesConsent = yield put(
      cookieActions.getCookie("cookieyes-consent")
    )
    const firstPromoterCookie = yield put(
      cookieActions.getCookie("_fprom_track")
    )
    const { entity_size, entity_type, other_entity_type } = userAttributes
    let attributes
    if (accountInviteToken) {
      attributes = {
        ...userAttributes,
        agreed_to_terms: true,
      }
    } else {
      attributes = {
        ...userAttributes,
        ...utmCookies,
        agreed_to_terms: true,
        cookie_yes_consent: cookieYesConsent,
        entity_size: entity_size?.value,
        entity_type: entity_type?.value,
        entity_other: entity_type?.value === "Other" ? other_entity_type : null,
        referring_user_id,
        first_promoter_tid: firstPromoterCookie,
        signup_selected_plan: signupSelectedPlan,
      }
    }

    const requestData = {
      ...(accountInviteToken && { token: accountInviteToken }),
      data: { attributes },
    }

    const email = action.values.toJS().email
    const registrationDigestHeaderPromise = call(
      registrationDigestHeader,
      email
    )
    const registrationDigestHeaderValue = yield registrationDigestHeaderPromise

    const options = {
      data: JSON.stringify(requestData),
      hasHeaders: true,
      headers: merge(defaultHeaders(), registrationDigestHeaderValue),
    }

    const { data, headers } = yield call(post, requestURL, options)
    if (data.meta?.token) storeToken(data.meta.token)
    yield put(actions.requestSuccess(mergeUseWithGeoHeaders(data, headers)))
    yield spawn(initThirdPartyTracking, "Signed Up")

    const user = yield select(selectCurrentUserRecord())
    yield call(updateMixpanelSuperProperties, user)
    yield call(identifyCurrentUser)

    yield put(
      trackingActions.trackMixpanel("Signed Up", {
        context:
          signupSelectedPlan && signupSelectedPlan.includes("enterprise-")
            ? "Enterprise Intent"
            : action.tracking.context,
        "Sign Up Trigger": action.tracking.signUpTrigger,
        "Referring User ID": referring_user_id,
        "Company Size": entity_size,
        marketplace: marketPage,
      })
    )
    updateMixpanelPeopleProperties(user)
    if (isProduction() || debugFlag("GA_TRACKING")) {
      gtag("set", "user_data", {
        email: user.email.toLowerCase(),
      })
      gtag("event", "submit", {
        event_label: "Google Signup",
        event_category: "Button",
      })
      gtag("event", "free_account_registration")

      //TikTok Event
      window.ttq?.identify({
        email: user.email.toLowerCase(),
      })
      window.ttq?.track("CompleteRegistration")

      yield put(trackingActions.trackMixpanel("free_account_registration"))
    }
    yield put(stopSubmit(signUpForm))
    yield put(referralActions.removeReferringUserId())
    yield put(destroy(signUpForm))

    if (action.callback) {
      yield call(action.callback)
    } else if (action.redirectPath) {
      yield put(push(action.redirectPath))
    }
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(signUpForm, { _error: err }))
  }
}

export function* watchSignUp() {
  yield takeLatest(types.SIGN_UP_SUBMIT, signUp)
}

// Old saga, to be replaced by below once tested in staging
export function* googleOauthSubmit(action) {
  try {
    const referring_user_id = yield select(selectReferringUserId())
    const { accountInviteToken, signupSelectedPlan } = action
    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const firstPromoterCookie = yield put(
      cookieActions.getCookie("_fprom_track")
    )
    const params = {
      ...utmCookies,
      ...(accountInviteToken && { token: accountInviteToken }),
      referring_user_id,
      first_promoter_tid: firstPromoterCookie,
      signup_selected_plan: signupSelectedPlan,
    }

    const response = yield call(googleOauth, params)
    if (response?.meta?.token) storeToken(response.meta.token)
    const newUser = response.meta?.new_user
    const marketPage = window.location.pathname.includes("market")

    yield put(actions.requestSuccess(response))

    const user = yield select(selectCurrentUserRecord())
    yield call(updateMixpanelSuperProperties, user)
    yield call(identifyCurrentUser)

    yield spawn(initThirdPartyTracking, "Signed In")

    if (newUser) {
      yield put(referralActions.removeReferringUserId())
      trackUserpilot("Signed Up")
      yield put(
        trackingActions.trackMixpanel("Signed Up", {
          context:
            signupSelectedPlan && signupSelectedPlan.includes("enterprise-")
              ? "Enterprise Intent"
              : action.tracking.context,
          "Sign Up Trigger": action.tracking.signUpTrigger,
          "Referring User ID": referring_user_id,
          "Signed Up Method": "Google",
          marketplace: marketPage,
        })
      )
      const user = yield select(selectCurrentUserRecord())
      updateMixpanelPeopleProperties(user)

      if (isProduction() || debugFlag("GA_TRACKING")) {
        gtag("set", "user_data", {
          email: user.email.toLowerCase(),
        })
        gtag("event", "submit", {
          event_label: "google-signup",
          event_category: "Button",
        })
        gtag("event", "free_account_registration")
        yield put(trackingActions.trackMixpanel("free_account_registration"))
        window?.ttq?.identify({
          email: user.email.toLowerCase(),
        })
        window?.ttq?.track("CompleteRegistration")
      }
    } else {
      yield put(
        trackingActions.trackMixpanel("Signed In", {
          Context: action.tracking.context,
          "Signed In Method": "Google",
          marketplace: marketPage,
        })
      )
    }

    if (action.redirectPath) {
      const path =
        newUser && action.redirectPath === "/"
          ? "/pricing"
          : action.redirectPath
      yield put(push(path))
    } else {
      yield put(modalActions.close())
    }

    if (
      !newUser &&
      action.mobilePlayerObject &&
      !isEmpty(action.mobilePlayerObject)
    ) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobilePlayerObject)
      )
    }

    if (action.callback) yield action.callback()
  } catch (e) {
    const errorMsg =
      e === "popup_closed"
        ? ""
        : e.message ||
          "Unable to get account information from Google.\nPlease try again."
    yield put(actions.googleOauthFailed(errorMsg))
  }
}

// Moves tracking into tracking saga and has tests
export function* newGoogleOauthSubmit(action) {
  try {
    const referring_user_id = yield select(selectReferringUserId())
    const { accountInviteToken, signupSelectedPlan } = action
    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const firstPromoterCookie = yield put(
      cookieActions.getCookie("_fprom_track")
    )
    const params = {
      ...utmCookies,
      ...(accountInviteToken && { token: accountInviteToken }),
      referring_user_id,
      first_promoter_tid: firstPromoterCookie,
      signup_selected_plan: signupSelectedPlan,
    }

    const response = yield call(googleOauth, params)
    if (response?.meta?.token) storeToken(response.meta.token)
    const newUser = response?.meta?.new_user
    const marketPage = window.location.pathname.includes("market")

    yield put(actions.requestSuccess(response))

    const user = yield select(selectCurrentUserRecord())
    yield call(updateMixpanelSuperProperties, user)
    yield call(identifyCurrentUser)

    if (newUser) {
      yield put(referralActions.removeReferringUserId())

      yield put(
        trackingActions.trackGoogleOauthSignUp({
          context:
            signupSelectedPlan && signupSelectedPlan.includes("enterprise-")
              ? "Enterprise Intent"
              : action.tracking.context,
          "Sign Up Trigger": action.tracking.signUpTrigger,
          "Referring User ID": referring_user_id,
          marketplace: marketPage,
        })
      )
    } else {
      yield put(
        trackingActions.trackGoogleOauthSignIn({
          Context: action.tracking.context,
          marketplace: marketPage,
        })
      )
    }

    if (action.redirectPath) {
      const path =
        newUser && action.redirectPath === "/"
          ? "/pricing"
          : action.redirectPath
      yield put(push(path))
    } else {
      yield put(modalActions.close())
    }

    if (
      !newUser &&
      action.mobilePlayerObject &&
      !isEmpty(action.mobilePlayerObject)
    ) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobilePlayerObject)
      )
    }

    if (action.callback) yield action.callback()
  } catch (e) {
    const errorMsg =
      e === "popup_closed"
        ? ""
        : e.message ||
          "Unable to get account information from Google.\nPlease try again."
    yield put(actions.googleOauthFailed(errorMsg))
  }
}

export function* watchGoogleOauthSubmit() {
  yield takeLatest(types.GOOGLE_OAUTH_SUBMIT, function*(action) {
    const flagEnabled = yield select(
      selectIsFlagEnabled("new_google_oauth_saga")
    )

    if (flagEnabled) {
      yield call(newGoogleOauthSubmit, action)
    } else {
      yield call(googleOauthSubmit, action)
    }
  })
}

export function* enterpriseSignUp(action) {
  const enterpriseSignUpForm = action.form
  yield put(startSubmit(enterpriseSignUpForm))
  try {
    const { password } = action.values.toJS()
    const { signUpToken } = action
    const requestPath = "enterprise_registrations"
    const requestURL = `${process.env.API_URL}/${requestPath}`

    const requestData = {
      data: {
        attributes: {
          password,
          token: signUpToken,
        },
      },
    }

    const options = {
      data: JSON.stringify(requestData),
      hasHeaders: true,
    }
    const { data, headers } = yield call(post, requestURL, options)
    if (data?.meta?.token) {
      yield call(storeToken, data.meta.token)
    }
    yield put(actions.requestSuccess(mergeUseWithGeoHeaders(data, headers)))

    const user = yield select(selectCurrentUserRecord())
    yield call(updateMixpanelSuperProperties, user)
    yield call(identifyCurrentUser)

    yield put(push("/"))
    yield put(stopSubmit(enterpriseSignUpForm))
    yield put(destroy(enterpriseSignUpForm))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(enterpriseSignUpForm, { _error: err }))
  }
}

export function* watchEnterpriseSignUp() {
  yield takeLatest(types.ENTERPRISE_SIGN_UP_SUBMIT, enterpriseSignUp)
}

export function* enterpriseModalSignUp(action) {
  const enterpriseSignUpForm = action.form
  const values = action.values
  const formData = { ...values, ...action.hubspotData }
  yield put(startSubmit(enterpriseSignUpForm))
  try {
    if (formData.password) {
      const referring_user_id = yield select(selectReferringUserId())
      const { accountInviteToken, signupSelectedPlan } = action
      const requestURL = `${process.env.API_URL}/secure_user_registrations`
      const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
      const firstPromoterCookie = yield put(
        cookieActions.getCookie("_fprom_track")
      )

      let attributes
      if (accountInviteToken) {
        attributes = { ...formData }
      } else {
        attributes = {
          ...formData,
          ...utmCookies,
          first_name: formData.first_name,
          last_name: formData.last_name,
          entity_size: formData.company_size?.value,
          entity_type: formData.entity_type?.value,
          entity_other:
            formData.entity_type?.value === "Other"
              ? formData.other_entity_type
              : null,
          referring_user_id,
          first_promoter_tid: firstPromoterCookie,
          signup_selected_plan: signupSelectedPlan,
        }
      }

      const registrationDigestHeaderPromise = call(
        registrationDigestHeader,
        formData.email
      )

      const registrationDigestHeaderValue = yield registrationDigestHeaderPromise
      const requestData = { data: { attributes } }

      const options = {
        data: JSON.stringify(requestData),
        hasHeaders: true,
        headers: merge(defaultHeaders(), registrationDigestHeaderValue),
      }

      const { data, headers } = yield call(post, requestURL, options)
      if (data.meta?.token) storeToken(data.meta.token)
      yield put(actions.requestSuccess(mergeUseWithGeoHeaders(data, headers)))

      const user = yield select(selectCurrentUserRecord())
      yield call(updateMixpanelSuperProperties, user)
      yield call(identifyCurrentUser)

      yield spawn(initThirdPartyTracking, "Signed Up")

      yield put(
        trackingActions.trackMixpanel("Signed Up", {
          Context: "Enterprise Intent",
          "Sign Up Trigger": action.tracking?.signUpTrigger,
          "Referring User ID": referring_user_id,
        })
      )

      if (isProduction() || debugFlag("GA_TRACKING")) {
        window?.ttq?.identify({
          email: user.email.toLowerCase(),
        })
        window?.ttq?.track("CompleteRegistration")
        gtag("set", "user_data", { email: user.email.toLowerCase() })
        gtag("event", "free_account_registration")
        yield put(trackingActions.trackMixpanel("free_account_registration"))
      }
      updateMixpanelPeopleProperties(user)
      yield put(referralActions.removeReferringUserId())
    }

    yield put(trackingActions.trackEnterpriseFormSubmit())

    const hubspotData = {
      fields: [
        { name: "firstname", value: formData.first_name },
        { name: "lastname", value: formData.last_name },
        { name: "email", value: formData.email },
        { name: "phone", value: formData.phone_number },
        { name: "company", value: formData.company_name },
        {
          name: "interested_in_api_",
          value: formData.interested_in_api_.value,
        },
        { name: "additional_notes", value: formData.additional_notes || "" },
      ],
      context: {
        hutk: formData.hubspotToken,
        pageUri: window.location.href,
        pageName: "Enterprise Contact Modal",
      },
    }
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    const options = {
      data: JSON.stringify(hubspotData),
      headers,
    }
    yield call(post, formData.url, options)

    yield put(stopSubmit(enterpriseSignUpForm))
    yield put(destroy(enterpriseSignUpForm))
    if (action.redirectPath) yield put(push(action.redirectPath))
    yield put(modalActions.close())
    yield put(uiActions.setNotice("Success! We've received your request."))
  } catch (err) {
    if (action.setServerError) yield action.setServerError(err)
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
    yield put(stopSubmit(enterpriseSignUpForm, { _error: err }))
  }
}
export function* watchEnterpriseModalSignUp() {
  yield takeLatest(types.ENTERPRISE_MODAL_SIGN_UP_SUBMIT, enterpriseModalSignUp)
}

export function* contactLicensingModal(action) {
  const contactLicensingForm = action.form
  const values = action.values
  const formData = { ...values, ...action.hubspotData }

  yield put(startSubmit(contactLicensingForm))
  try {
    const hubspotData = {
      fields: [
        { name: "firstname", value: formData.first_name },
        { name: "lastname", value: formData.last_name },
        { name: "email", value: formData.email },
        { name: "company", value: formData.company_name },
        { name: "additional_notes", value: formData.additional_notes },
        { name: "number_of_songs", value: formData.number_of_songs },
        { name: "type_of_media", value: formData.type_of_media.value },
        {
          name: "other_type_of_media",
          value: formData.other_type_of_media,
        },
        { name: "brand_client", value: formData.brand_client },

        {
          name: "working_campaign_title",
          value: formData.working_campaign_title,
        },
        {
          name: "production_company",
          value: formData.production_company,
        },
        { name: "music_budget", value: formData.music_budget },

        {
          name: "distribution_type",
          value: formData.distribution_type.join(";"),
        },
        {
          name: "other_distribution_type",
          value: formData.other_distribution_type,
        },
        { name: "territory", value: formData.territory.value },
        { name: "other_territory", value: formData.other_territory },
        { name: "term", value: formData.term.value },
        { name: "term_other", value: formData.term_other },
        { name: "song_id", value: formData.song_id },
        { name: "song_artist", value: formData.song_artist },
        { name: "song_title", value: formData.song_title },
      ],
      context: {
        hutk: formData.hubspotToken,
        pageUri: window.location.href,
        pageName: "Business Licensing Modal",
      },
    }
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    const options = {
      data: JSON.stringify(hubspotData),
      headers,
    }
    yield call(post, formData.url, options)
    yield put(stopSubmit(contactLicensingForm))
    yield put(destroy(contactLicensingForm))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
    yield put(stopSubmit(contactLicensingForm, { _error: err }))
  }
}

export function* watchContactLicensingSubmit() {
  yield takeLatest(types.CONTACT_LICENSING_MODAL_SUBMIT, contactLicensingModal)
}

export function* contactSfxLicensingModal(action) {
  const contactLicensingForm = action.form
  const values = action.values
  const formData = { ...values, ...action.hubspotData }

  yield put(startSubmit(contactLicensingForm))
  try {
    const hubspotData = {
      fields: [
        { name: "firstname", value: formData.first_name },
        { name: "lastname", value: formData.last_name },
        { name: "email", value: formData.email },
        { name: "company", value: formData.company_name },
        { name: "additional_notes", value: formData.additional_notes },
        { name: "number_of_songs", value: formData.number_of_songs },
        { name: "type_of_media", value: formData.type_of_media.value },
        {
          name: "other_type_of_media",
          value: formData.other_type_of_media,
        },
        { name: "brand_client", value: formData.brand_client },

        {
          name: "working_campaign_title",
          value: formData.working_campaign_title,
        },
        {
          name: "production_company",
          value: formData.production_company,
        },
        { name: "music_budget", value: formData.music_budget },

        {
          name: "distribution_type",
          value: formData.distribution_type.join(";"),
        },
        {
          name: "other_distribution_type",
          value: formData.other_distribution_type,
        },
        { name: "territory", value: formData.territory.value },
        { name: "other_territory", value: formData.other_territory },
        { name: "term", value: formData.term.value },
        { name: "term_other", value: formData.term_other },
        { name: "song_id", value: formData.song_id },
        { name: "song_title", value: formData.song_title },
      ],
      context: {
        hutk: formData.hubspotToken,
        pageUri: window.location.href,
        pageName: "Business Licensing Modal",
      },
    }
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    const options = {
      data: JSON.stringify(hubspotData),
      headers,
    }
    yield call(post, formData.url, options)
    yield put(stopSubmit(contactLicensingForm))
    yield put(destroy(contactLicensingForm))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
    yield put(stopSubmit(contactLicensingForm, { _error: err }))
  }
}

export function* watchContactSfxLicensingSubmit() {
  yield takeLatest(
    types.CONTACT_SFX_LICENSING_MODAL_SUBMIT,
    contactSfxLicensingModal
  )
}

export function* teamSeatMaxSubmit(action) {
  const { form, values, hubspotData } = action

  yield put(startSubmit(form))
  try {
    const hubspotFormData = {
      fields: [
        { name: "firstname", value: values.firstname },
        { name: "lastname", value: values.lastname },
        { name: "email", value: values.email },
      ],
      context: {
        hutk: hubspotData.hubspotToken,
        pageUri: window.location.href,
        pageName: "Team Seat Limit Reached Modal",
      },
    }
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    const options = {
      data: JSON.stringify(hubspotFormData),
      headers,
    }

    yield call(post, hubspotData.url, options)
    yield put(stopSubmit(form))
    yield put(destroy(form))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
    yield put(stopSubmit(form, { _error: err }))
  }
}

export function* watchTeamSeatModalSubmit() {
  yield takeLatest(types.TEAM_SEAT_MAX_MODAL_SUBMIT, teamSeatMaxSubmit)
}

export function* signOutEverywhereSubmit() {
  try {
    const requestURL = `${process.env.API_URL}/sessions/delete_all`
    const response = yield call(_delete, requestURL)
    yield put(actions.requestSuccess(response))
    removeToken()
    mixpanel.reset()
    yield put(push("/sign_in"))
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchSignOutEverywhereSubmit() {
  yield takeLatest(types.SIGN_OUT_EVERYWHERE_SUBMIT, signOutEverywhereSubmit)
}

export function* initThirdPartyTracking(actionName = null) {
  try {
    const user = yield select(selectCurrentUserRecord())
    setLiveChatUserInfo(user)
    initUserPilot(user)
    if (actionName) trackUserpilot(actionName)
    initAskNicely(user)
  } catch (err) {
    console.error(err)
  }
}

export function* forgotPassword(action) {
  yield put(startSubmit(action.form))

  try {
    const requestURL = `${process.env.API_URL}/passwords`
    const data = {
      data: {
        type: "passwords",
        attributes: {
          email: action.email,
        },
      },
    }
    const options = { data: JSON.stringify(data) }
    yield call(post, requestURL, options)
    yield put(
      uiActions.setNotice({
        message:
          "If there is an account in our system with that email address, you should receive an email momentarily with further instructions!",
        fadeOut: false,
      })
    )
    yield put(stopSubmit(action.form))
    yield put(reset(action.form))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(action.form, { _error: err.message }))
  }
}

export function* watchForgotPassword() {
  yield takeLatest(types.FORGOT_PASSWORD_SUBMIT, forgotPassword)
}

export function* updateProfile(action) {
  yield put(startSubmit(action.form))

  try {
    const formAttributes = action.values.toJS()
    if (formAttributes.entity_type !== "Other") {
      formAttributes.entity_other = null
    }
    const currentUserId = currentUserIdFromToken()
    const requestURL = `${process.env.API_URL}/users/${currentUserId}`
    const data = {
      data: {
        type: "users",
        id: currentUserId.toString(),
        attributes: formAttributes,
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(patch, requestURL, options)
    let authToken
    // If a new session was returned, update it in our storage
    if (response.included) {
      response.included.forEach((obj) => {
        if (/sessions/.test(obj.type)) {
          authToken = obj.attributes.jwt
        }
      })
    }
    yield put(actions.requestSuccess(response, authToken))
    yield put(stopSubmit(action.form))
    const user = yield select(selectCurrentUserRecord())
    yield put(
      initialize(action.form, {
        user: {
          first_name: user.first_name,
          last_name: user.last_name,
          company_name: user.company_name,
          email: user.email,
        },
      })
    )
    updateMixpanelPeopleProperties(user)
    yield put(push("/account/profile"))
    yield delay(200)
    yield put(uiActions.setNotice("Profile updated successfully!"))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(action.form, { _error: err }))
  }
}

export function* watchUpdateProfileSubmit() {
  yield takeLatest(types.UPDATE_PROFILE_SUBMIT, updateProfile)
}

export function* changePassword(action) {
  yield put(startSubmit(action.form))

  try {
    const formAttributes = action.values.toJS()
    const currentUserId = currentUserIdFromToken()
    const requestURL = `${process.env.API_URL}/users/${currentUserId}/change_password`
    const options = {
      data: JSON.stringify({
        data: {
          type: "users",
          id: currentUserId.toString(),
          attributes: formAttributes,
        },
      }),
    }
    yield call(patch, requestURL, options)
    yield put(stopSubmit(action.form))
    yield put(uiActions.setNotice("Your password has been changed"))
    yield put(modalActions.close())
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(action.form, { _error: err }))
  }
}

export function* watchChangePasswordSubmit() {
  yield takeLatest(types.CHANGE_PASSWORD_SUBMIT, changePassword)
}

export function* updateBillingInfoSubmit(action) {
  yield put(startSubmit(action.form))

  try {
    const attributes = action.values
    const currentUserId = currentUserIdFromToken()
    const requestURL = `${process.env.API_URL}/users/${currentUserId}/update_billing_info`
    const data = {
      data: {
        type: "users",
        id: currentUserId.toString(),
        attributes,
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(patch, requestURL, options)
    yield put(actions.requestSuccess(response))
    yield put(stopSubmit(action.form))
    if (action.modal) {
      yield put(modalActions.close())
      yield put(actions.load())
    } else {
      yield put(push("/account/billing"))
    }
    yield put(uiActions.setNotice("Billing info updated successfully!"))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(stopSubmit(action.form, { _error: err }))
  }
}

export function* watchupdateBillingInfoSubmit() {
  yield takeLatest(types.UPDATE_BILLING_INFO_SUBMIT, updateBillingInfoSubmit)
}

export function* authorizeWithThreeDS({
  chargebeeCard,
  checkoutAttributes,
  paymentIntent,
}) {
  const currentUser = yield select(selectCurrentUserRecord())
  const threeDSData = {
    billingAddress: {
      zip: checkoutAttributes.zip,
    },
    email: currentUser.email,
  }
  try {
    const response = yield call(
      chargebeeCard.authorizeWith3ds,
      paymentIntent,
      threeDSData
    )
    return response
  } catch (error) {
    return error
  }
}

export function* paymentRequestButtonSubmit(action) {
  try {
    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const { checkoutAttributes, authorizedPaymentIntent } = action
    const paymentType = checkoutAttributes.payment_type
    delete checkoutAttributes.payment_type

    const cardCheckoutAttributes = {
      stripe_payment_intent: authorizedPaymentIntent,
      ...checkoutAttributes,
    }
    const requestURL = `${
      process.env.API_URL
    }/users/${currentUserIdFromToken()}/subscriptions`
    const data = {
      data: {
        type: "subscriptions",
        attributes: cardCheckoutAttributes,
        payment_type: paymentType,
        utm_cookies: utmCookies,
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)
    if (Cookie.get("mk_fid") === "referral") Cookie.remove("mk_fid")
    if (Cookie.get("adobe_registration")) Cookie.remove("adobe_registration")

    if (action.checkoutAttributes.plan_id.includes("twitch")) {
      yield put(push(`/user_info/ep?plan=${action.checkoutAttributes.plan_id}`))
    } else {
      yield put(push(`/user_info/yt?plan=${action.checkoutAttributes.plan_id}`))
    }
    if (authorizedPaymentIntent) {
      trackBingEvent("subscribe", {
        event_category: "subscription",
        event_value: authorizedPaymentIntent?.amount / 100,
        revenue_value: authorizedPaymentIntent?.amount / 100,
        currency: authorizedPaymentIntent?.currency,
      })
    }

    if (response?.meta.token) {
      storeToken(response.meta.token)
    }
    yield put(actions.requestSuccess(response))
    const userRecord = new UserRecord(makeRecord(response.data))
    setLiveChatUserInfo(userRecord)
    trackUserpilot("Subscription Created")
    yield spawn(
      trackConversionAnalytics,
      response.meta,
      response.data,
      action.checkoutAttributes.plan_id.includes("enterprise-business")
    )
    yield put(actions.removePaymentIntent())
    yield put(actions.amobeeSuccess())
    yield put(actions.paymentRequestButtonSuccess())
    yield put(actions.load())
  } catch (err) {
    yield put(actions.removePaymentIntent())
    yield put(stopSubmit(action.form, { _error: err }))
    yield put(actions.requestFailed())
  }
}

export function* newPaymentRequestButtonSubmit(action) {
  try {
    const { checkoutAttributes, authorizedPaymentIntent } = action

    const cardCheckoutAttributes = {
      stripe_payment_intent: authorizedPaymentIntent,
      ...checkoutAttributes,
    }

    yield put(subscriptionActions.create(cardCheckoutAttributes))

    const subscriptionResult = yield take([
      subscriptionTypes.CREATE_SUCCESS,
      subscriptionTypes.CREATE_FAILED,
    ])

    // The subscription creation failure will set a UI error, but we still
    // want this form stuff to run. But we will leave the catch below
    // in case something else errors unexpectedly
    if (subscriptionResult.type === subscriptionTypes.CREATE_FAILED) {
      yield call(
        handlePaymentRequestError,
        action.form,
        subscriptionResult.error
      )
      return
    }

    const { subscription, response } = subscriptionResult

    yield put(
      trackingActions.trackBing("subscribe", {
        event_category: "subscription",
        event_value: authorizedPaymentIntent?.amount / 100,
        revenue_value: authorizedPaymentIntent?.amount / 100,
        currency: authorizedPaymentIntent?.currency,
      })
    )

    if (response?.meta.token) yield call(storeToken, response.meta.token)
    yield put(actions.requestSuccess(response))
    const userRecord = new UserRecord(makeRecord(response.data))
    yield call(setLiveChatUserInfo, userRecord)
    yield put(trackingActions.trackUserpilot("Subscription Created"))

    yield spawn(
      trackConversionAnalytics,
      response.meta,
      response.data,
      action.checkoutAttributes.plan_id.includes("enterprise-business")
    )

    yield call(postSubscriptionCreated, subscription)
    yield put(actions.paymentRequestButtonSuccess())
    yield put(actions.load())
  } catch (err) {
    yield call(handlePaymentRequestError, action.form, err)
  }
}

export function* handlePaymentRequestError(form, err) {
  yield put(actions.removePaymentIntent())
  yield put(stopSubmit(form, { _error: err }))
  yield put(actions.requestFailed())
}

export function* watchPaymentRequestButtonSubmit() {
  yield takeLatest(types.PAYMENT_REQUEST_BUTTON_SUBMIT, function*(action) {
    const flagEnabled = yield select(
      selectIsFlagEnabled("new_subscription_payment_request_saga")
    )

    if (flagEnabled) {
      yield call(newPaymentRequestButtonSubmit, action)
    } else {
      yield call(paymentRequestButtonSubmit, action)
    }
  })
}

// B variant of the below monolithic `checkoutFormSubmit` saga.
//
// This refactored saga is a "conductor". It does the three main things involved in
// subscribing:
//
//    1. Authorize payment intent
//    2. Create the subscription
//    3. Tracking
//
// It also handles misc things like redirection, errors.
export function* newCheckoutFormSubmit(action) {
  try {
    // Authorize payment intent
    yield put(
      checkoutActions.authorizePaymentIntent(
        action.chargebeeCard,
        action.checkoutAttributes
      )
    )
    const paymentIntentResult = yield take([
      checkoutTypes.AUTHORIZE_PAYMENT_INTENT_SUCCESS,
      checkoutTypes.AUTHORIZE_PAYMENT_INTENT_FAILED,
    ])
    if (
      paymentIntentResult.type === checkoutTypes.AUTHORIZE_PAYMENT_INTENT_FAILED
    ) {
      throw new Error(paymentIntentResult.error)
    }

    // Create the subscription
    yield put(
      subscriptionActions.create(paymentIntentResult.checkoutAttributes)
    )
    const subscriptionResult = yield take([
      subscriptionTypes.CREATE_SUCCESS,
      subscriptionTypes.CREATE_FAILED,
    ])
    if (subscriptionResult.type === subscriptionTypes.CREATE_FAILED) {
      throw new Error(subscriptionResult.error)
    }

    // `response` is the full response from the subscription creation endpoint.
    // Needed for tracking. Post tracking refactor, only the `subscription` will
    // be included in the action
    const { subscription, response } = subscriptionResult

    // This is hacky but the below bing event is using payment intent data even
    // with PayPal payments (which don't use payment intents). Will refactor this
    // along with the rest of tracking. Safenav so we don't have to mock `meta`
    // in tests because it's annoying and we're going to refactor anyways.
    const paymentIntent = {
      amount: response.meta.google_ga4_data?.value,
      currency_code: response.meta.google_ga4_data?.currency,
    }
    yield call(trackBingEvent, "subscribe", {
      event_category: "subscription",
      event_value: paymentIntent.amount / 100,
      revenue_value: paymentIntent.amount / 100,
      currency: paymentIntent.currency_code,
    })
    yield call(trackUserpilot, "Subscription Created")
    yield spawn(
      trackConversionAnalytics,
      response.meta,
      response.data,
      subscription.plan_id.includes("enterprise-business")
    )

    // Post-sub clerical stuff
    yield call(postSubscriptionCreated, subscription)
  } catch (err) {
    yield put(stopSubmit(action.form, { _error: err }))
  } finally {
    yield put(stopSubmit(action.form))
  }
}

export function* postSubscriptionCreated(subscription) {
  if (Cookie.get("mk_fid") === "referral") Cookie.remove("mk_fid")
  if (Cookie.get("adobe_registration")) Cookie.remove("adobe_registration")

  if (subscription.plan_id.includes("twitch")) {
    yield put(push(`/user_info/ep?plan=${subscription.plan_id}`))
  } else {
    yield put(push(`/user_info/yt?plan=${subscription.plan_id}`))
  }

  yield put(actions.removePaymentIntent())
  yield put(actions.amobeeSuccess())
  yield put(cartActions.setCartStorage([]))
}

/**
 * Checkout Form Submit Flow
 * 1) Create a payment intent based on the most recent estimate
 * 2) Authorize the payment intent using ChargebeeJS which is a reference to our Chargebee/ComponentGroup component
 * 3) Use Authorized payment intent to complete checkout
 * 4) Remove payment intent in case of errors
 */

export function* checkoutFormSubmit(action) {
  try {
    const { chargebeeCard, checkoutAttributes } = action
    const estimate = yield select(selectCurrentUserEstimate())
    const isPaypalPayment = !!checkoutAttributes.billing_agreement_token
    const paymentIntentAttributes = {
      amount: estimate.invoice_estimate.total,
      currency_code: estimate.invoice_estimate.currency_code,
    }
    yield call(createOrUpdatePaymentIntent, {
      attributes: paymentIntentAttributes,
    })
    const paymentIntent = yield select(selectCurrentUserPaymentIntent())
    const paymentIntentResponse = yield call(authorizeWithThreeDS, {
      chargebeeCard,
      checkoutAttributes,
      paymentIntent,
    })
    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))

    if (
      !isPaypalPayment &&
      paymentIntentResponse &&
      paymentIntentResponse.status !== "authorized"
    ) {
      yield put(actions.removePaymentIntent())
      yield put(
        stopSubmit(action.form, {
          _error: { message: paymentIntentResponse.displayMessage },
        })
      )
      yield put(actions.requestFailed())
      return false
    }

    const cardCheckoutAttributes = {
      payment_intent: paymentIntentResponse,
      ...checkoutAttributes,
    }

    const requestURL = `${
      process.env.API_URL
    }/users/${currentUserIdFromToken()}/subscriptions`
    const data = {
      data: {
        type: "subscriptions",
        attributes: cardCheckoutAttributes,
        utm_cookies: utmCookies,
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)
    if (Cookie.get("mk_fid") === "referral") Cookie.remove("mk_fid")
    if (Cookie.get("adobe_registration")) Cookie.remove("adobe_registration")

    if (action.checkoutAttributes.plan_id.includes("twitch")) {
      yield put(push(`/user_info/ep?plan=${action.checkoutAttributes.plan_id}`))
    } else {
      yield put(push(`/user_info/yt?plan=${action.checkoutAttributes.plan_id}`))
    }

    trackBingEvent("subscribe", {
      event_category: "subscription",
      event_value: paymentIntentAttributes.amount / 100,
      revenue_value: paymentIntentAttributes.amount / 100,
      currency: paymentIntentAttributes.currency_code,
    })

    if (response?.meta?.token) storeToken(response.meta.token)
    yield put(actions.requestSuccess(response))
    const userRecord = new UserRecord(makeRecord(response.data))
    yield put(cartActions.setCartStorage([]))
    setLiveChatUserInfo(userRecord)
    trackUserpilot("Subscription Created")
    yield spawn(
      trackConversionAnalytics,
      response.meta,
      response.data,
      action.checkoutAttributes.plan_id.includes("enterprise-business")
    )

    yield put(actions.removePaymentIntent())
    yield put(actions.amobeeSuccess())
  } catch (err) {
    yield put(actions.removePaymentIntent())
    yield put(stopSubmit(action.form, { _error: err }))
    yield put(actions.requestFailed())
  }
}

export function* watchCheckoutFormSubmit() {
  yield takeLatest(types.CHECKOUT_FORM_SUBMIT, function*(action) {
    const flagEnabled = yield select(
      selectIsFlagEnabled("new_subscription_card_checkout_saga")
    )

    if (flagEnabled) {
      yield call(newCheckoutFormSubmit, action)
    } else {
      yield call(checkoutFormSubmit, action)
    }
  })
}

export function* cartCheckoutFormSubmit(action) {
  try {
    const { chargebeeCard, checkoutAttributes } = action
    const estimate = yield select(selectInvoiceEstimate())
    const paymentSource = yield select(selectCurrentUserPaymentSource())
    const billingToken = !!checkoutAttributes.payment_info
      .billing_agreement_token
    const isPaypalPayment =
      (paymentSource && paymentSource.paypal) || billingToken
    if (!isPaypalPayment && !paymentSource) {
      const paymentIntentAttributes = {
        amount: estimate.invoice_estimate.total,
        currency_code: estimate.invoice_estimate.currency_code,
      }
      yield call(createOrUpdatePaymentIntent, {
        attributes: paymentIntentAttributes,
      })
      const paymentIntent = yield select(selectCurrentUserPaymentIntent())
      const paymentIntentResponse = yield call(authorizeWithThreeDS, {
        chargebeeCard,
        checkoutAttributes: checkoutAttributes.billing_address,
        paymentIntent,
      })
      if (
        paymentIntentResponse &&
        paymentIntentResponse.status !== "authorized"
      ) {
        yield put(actions.removePaymentIntent())
        yield put(
          stopSubmit(action.form, {
            _error: { message: paymentIntentResponse.displayMessage },
          })
        )
        yield put(actions.requestFailed())
        return false
      }
      checkoutAttributes.payment_info.payment_intent = paymentIntentResponse
    }

    const currentUserId = currentUserIdFromToken()
    const billingRequestUrl = `${process.env.API_URL}/users/${currentUserId}/update_billing_info`
    const billingData = {
      data: {
        type: "users",
        id: currentUserId.toString(),
        attributes: {
          billing_address: checkoutAttributes.billing_address,
          vat_number: checkoutAttributes.vat_number,
          vat_number_prefix: checkoutAttributes.vat_number_prefix,
        },
      },
    }
    const billingOptions = { data: JSON.stringify(billingData) }
    yield call(patch, billingRequestUrl, billingOptions)
    delete checkoutAttributes.billing_address

    const requestURL = `${process.env.API_URL}/cart_sales`

    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const data = {
      data: {
        type: "sales",
        attributes: checkoutAttributes,
        utm_cookies: utmCookies,
      },
    }

    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)
    if (response?.meta?.token) storeToken(response.meta.token)

    yield put(trackingActions.trackCartPurchase(response))

    yield put(actions.removePaymentIntent())
    yield put(actions.load())

    yield put(push(checkoutAttributes.redirectPath))
    yield put(cartActions.setCartStorage([]))
  } catch (err) {
    yield put(actions.removePaymentIntent())
    yield put(actions.removePaymentSource())
    yield put(stopSubmit(action.form, { _error: err }))
    yield put(actions.requestFailed())
  }
}

export function* watchCartCheckoutFormSubmit() {
  yield takeLatest(types.CART_CHECKOUT_FORM_SUBMIT, cartCheckoutFormSubmit)
}

export function* trackConversionAnalytics(
  responseMeta,
  responseData,
  businessPlan = false
) {
  try {
    const subscriptionPlanId = responseData?.attributes?.subscription?.plan_id
    const isSubscription = subscriptionPlanId && subscriptionPlanId.length > 0
    const {
      bing_event_data,
      facebook_pixel_data,
      google_adwords_data,
      google_ga4_data,
      shareasale_query_params,
      tik_tok_data,
      tik_tok_data_ltv,
      revenue_data,
    } = responseMeta
    const transactionId = google_ga4_data?.transaction_id
    const lifetimeValue = revenue_data?.lifetime_value

    if (isProduction() || debugFlag("GA_TRACKING")) {
      const user = yield select(selectCurrentUserRecord())

      window?.ttq?.identify({
        email: user.email.toLowerCase(),
      })

      // GOOGLE ADWORDS CONVERSION EVENT
      gtag("set", "user_data", {
        email: user.email.toLowerCase(),
      })
      if (google_adwords_data) {
        gtag("event", "conversion", google_adwords_data)
      }

      if (google_ga4_data) {
        gtag("event", "purchase", google_ga4_data)
      }

      if (bing_event_data) {
        trackBingEvent("PRODUCT_PURCHASE", bing_event_data)
      }

      if (isSubscription) {
        gtag("event", "purchased_subscription", { plan: subscriptionPlanId })
        gtag("event", "conversion", {
          send_to: "AW-939466162/Ci2mCM-R-48ZELK7_L8D",
          value: lifetimeValue,
          currency: "USD",
          transaction_id: transactionId,
        })

        //BING EVENT
        identifyBing(user)
        trackBingEvent("purchase_subscribe_ltv_m12_v1", {
          revenue_value: lifetimeValue,
          currency: "USD",
        })

        // TIKTOK PIXEL EVENT
        window?.ttq?.track("PlaceAnOrder", tik_tok_data_ltv)

        yield put(trackingActions.trackMixpanel("purchased_subscription"))
      }

      //TIKTOK PIXEL EVENT
      if (tik_tok_data && !isSubscription) {
        window?.ttq?.track("PlaceAnOrder", tik_tok_data)
      }

      // FACEBOOK PIXEL EVENT
      try {
        fbq("track", "CompleteRegistration", facebook_pixel_data)
      } catch (e) {
        console.error(e)
      }

      // SHAREASALE TRACKING PIXEL https://shareasale.com/itp/pdfs/CustomCart.pdf
      const shareASalePixel = new Image(1, 1)
      shareASalePixel.src = `https://shareasale.com/sale.cfm?${queryString.toQuery(
        shareasale_query_params
      )}`
      const shareASaleScript = document.createElement("script")
      shareASaleScript.src = "https://www.dwin1.com/19038.js"
      shareASaleScript.type = "text/javascript"
      shareASaleScript.defer = "defer"
      document.body.append(shareASalePixel, shareASaleScript)
    }
  } catch (err) {
    console.error(err)
  }
}

export function* prosperstackCancelSubscription(action) {
  try {
    const requestURL = `${process.env.API_URL}/subscriptions/${action.subscriptionId}/subscription_cancellation_events`

    const data = {
      data: {
        attributes: action.values,
      },
    }

    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)
    const currentTermEnd = formatDate(
      response.data.attributes.subscription.current_term_end
    )
    yield put(actions.load())
    const user = yield select(selectCurrentUserRecord())
    let nextPath = "/"
    let message = "Your subscription has been cancelled."
    if (user && user.hasSubscription()) {
      nextPath = "/account/billing"
      message = `Your subscription will remain active until ${currentTermEnd} and will not auto-renew.`
    }
    yield put(push(nextPath))
    yield put(
      modalActions.open("NotificationModal", {
        title: "Successful Cancellation",
        status: "success",
        message,
      })
    )
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchProsperstackCancelSubscription() {
  yield takeLatest(
    types.PROSPERSTACK_CANCEL_SUBSCRIPTION,
    prosperstackCancelSubscription
  )
}

export function* cancelSubscription(action) {
  yield put(startSubmit(action.form))

  try {
    const requestURL = `${process.env.API_URL}/subscriptions/${action.subscriptionId}/subscription_cancellation_events`

    const data = {
      data: {
        attributes: action.values.toJS(),
      },
    }

    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)
    const currentTermEnd = formatDate(
      response.data.attributes.subscription.current_term_end
    )
    yield put(stopSubmit(action.form))
    yield put(destroy(action.form))
    yield put(actions.load())
    const user = yield select(selectCurrentUserRecord())
    let nextPath = "/"
    let message = "Your subscription has been cancelled."
    if (user && user.hasSubscription()) {
      nextPath = "/account/billing"
      message = `Your subscription will remain active until ${currentTermEnd} and will not auto-renew.`
    }
    yield put(push(nextPath))
    yield put(
      modalActions.open("NotificationModal", {
        title: "Successful Cancellation",
        status: "success",
        message,
      })
    )
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
    yield put(stopSubmit(action.form, { _error: err }))
  }
}

export function* watchCancelSubscription() {
  yield takeLatest(types.CANCEL_SUBSCRIPTION_SUBMIT, cancelSubscription)
}

export function* retrievePaymentSource() {
  try {
    const user = yield select(selectCurrentUserRecord())
    const requestURL = `${process.env.API_URL}/users/${user.id}/payment_sources`
    const response = yield call(get, requestURL)
    yield put(actions.retrievePaymentSourceSuccess(response))
    yield put(actions.load())
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchRetrievePaymentSource() {
  yield takeLatest(types.RETRIEVE_PAYMENT_SOURCE, retrievePaymentSource)
}

export function* retrieveSubscriptionWithScheduledChanges() {
  try {
    const user = yield select(selectCurrentUserRecord())
    const requestURL = `${process.env.API_URL}/subscriptions/${user.subscription.id}/scheduled_changes`
    const response = yield call(get, requestURL)
    yield put(actions.retrieveSubscriptionWithScheduledChangesSuccess(response))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchRetrieveSubscriptionWithScheduledChanges() {
  yield takeEvery(
    types.RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES,
    retrieveSubscriptionWithScheduledChanges
  )
}

export function* previewUpcomingInvoiceSubmit(action) {
  try {
    const user = yield select(selectCurrentUserRecord())
    const requestURL = `${process.env.API_URL}/subscriptions/${user.subscription.id}/estimates`
    const data = {
      data: {
        attributes: {
          plan_id: action.newPlanId,
          coupon_code: action.couponCode,
          force_term_reset: true,
        },
      },
    }
    const options = { data: JSON.stringify(data) }
    const { response } = yield call(post, requestURL, options)
    const amountDue = response.estimate?.invoice_estimate?.amount_due
    const taxes = response.estimate?.invoice_estimate?.line_items[0].tax_amount
    const costMinusTaxes = amountDue - taxes
    const isDualInvoice = !!action.isDualInvoice
    yield put(
      actions.previewUpcomingInvoiceComplete(
        response,
        costMinusTaxes,
        isDualInvoice
      )
    )
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchPreviewUpcomingInvoiceSubmit() {
  yield takeEvery(
    types.PREVIEW_UPCOMING_INVOICE_SUBMIT,
    previewUpcomingInvoiceSubmit
  )
}

export function* upsellSubmit(action) {
  try {
    const user = yield select(selectCurrentUserRecord())
    const subscription = user.subscription
    const subId = subscription.id.toString()
    const requestURL = `${process.env.API_URL}/subscriptions/${subId}`
    const data = {
      data: {
        type: "subscriptions",
        id: subId,
        attributes: {
          plan_id: action.planId,
          coupon_code: action.couponCode,
          force_term_reset: true,
          end_of_term: action.isDowngrade,
        },
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(patch, requestURL, options)
    const authToken = response?.meta?.token
    yield put(actions.requestSuccess(response, authToken))
    yield put(actions.load())
    if (action.isDowngrade) {
      yield call(retrieveSubscriptionWithScheduledChanges)
    }
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchUpsellSubmit() {
  yield takeLatest(types.UPSELL_SUBMIT, upsellSubmit)
}

export function* reactivateSubmit(action) {
  try {
    const user = yield select(selectCurrentUserRecord())
    const subscription = user.subscription
    const subId = subscription.id.toString()
    let response
    const reactivateUrl = `${process.env.API_URL}/subscriptions/${subId}/remove_scheduled_cancellation`
    if (action.couponCode) {
      yield call(patch, reactivateUrl)
      const applyCouponUrl = `${process.env.API_URL}/subscriptions/${subId}`
      const data = {
        data: {
          type: "subscriptions",
          id: subId,
          attributes: {
            plan_id: action.planId,
            coupon_code: action.couponCode,
            force_term_reset: false,
            end_of_term: true,
          },
        },
      }
      const options = { data: JSON.stringify(data) }
      response = yield call(patch, applyCouponUrl, options)
    } else {
      response = yield call(patch, reactivateUrl)
    }
    const authToken = response?.meta?.token
    yield put(actions.requestSuccess(response, authToken))
    yield put(actions.retrieveSubscriptionWithScheduledChanges())
    yield put(actions.load())
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.requestFailed())
  }
}

export function* watchReactivateSubmit() {
  yield takeLatest(types.REACTIVATE_SUBMIT, reactivateSubmit)
}

export function* trackUpsell() {
  try {
    const user = yield select(selectCurrentUserRecord())
    const subId = user.subscription && user.subscription.id
    const requestURL = `${process.env.API_URL}/subscriptions/${subId}/track_upsell`
    const data = {
      data: {
        type: "subscriptions",
        id: subId,
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(patch, requestURL, options)
    yield put(actions.requestSuccess(response))
    yield put(actions.load())
  } catch (err) {
    yield put(actions.requestFailed())
  }
}

export function* watchTrackUpsell() {
  yield takeLatest(types.TRACK_UPSELL, trackUpsell)
}

export function* unlinkOauthAccounts() {
  try {
    const currentUserId = currentUserIdFromToken()
    const requestURL = `${process.env.API_URL}/users/${currentUserId}/unlink_oauth_accounts`
    const response = yield call(patch, requestURL)
    yield put(actions.requestSuccess(response))
    yield put(uiActions.setNotice("Oauth accounts successfully unlinked"))
    yield put(trackingActions.trackMixpanel("Unlinked Google Account"))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(uiActions.setNotice("Oauth accounts could not be unlinked"))
  }
}

export function* watchUnlinkOauthAccounts() {
  yield takeLatest(types.UNLINK_OAUTH_ACCOUNTS_SUBMIT, unlinkOauthAccounts)
}

export function* clearAvatar() {
  try {
    const currentUserId = currentUserIdFromToken()
    const requestURL = `${process.env.API_URL}/users/${currentUserId}/clear_avatar`
    const response = yield call(patch, requestURL)
    yield put(actions.requestSuccess(response))
    yield put(actions.clearAvatarComplete())
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.clearAvatarComplete())
  }
}

export function* updateAvatar(action) {
  try {
    const currentUserId = currentUserIdFromToken()
    const requestURL = `${process.env.API_URL}/users/${currentUserId}`
    const response = yield call(uploadFileInChunks, {
      file: action.file,
      url: requestURL,
      method: "PATCH",
      data: {
        type: "users",
        id: currentUserId.toString(),
      },
      fileKeyPath: "data.attributes.avatar",
    })
    yield put(actions.requestSuccess(response))
    yield put(actions.updateAvatarComplete())
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(actions.updateAvatarComplete())
  }
}

export function* watchClearAvatar() {
  yield takeLatest(types.CLEAR_AVATAR_SUBMIT, clearAvatar)
}

export function* watchUpdateAvatar() {
  yield takeLatest(types.UPDATE_AVATAR_SUBMIT, updateAvatar)
}

export function* trackUpsellAttempt() {
  try {
    const requestURL = `${process.env.API_URL}/upsell_attempts`
    yield call(post, requestURL)
    yield put(actions.load())
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchTrackUpsellAttempt() {
  yield takeLatest(types.TRACK_UPSELL_ATTEMPT, trackUpsellAttempt)
}

export function* estimateSubscription(action) {
  try {
    const invoiceEstimate = action.estimatesAttributes.invoice
    const namespace = invoiceEstimate ? "invoice" : "subscription"
    const requestURL = `${
      process.env.API_URL
    }/users/${currentUserIdFromToken()}/${namespace}_estimates`
    const data = {
      data: {
        attributes: action.estimatesAttributes,
      },
    }
    const options = { data: JSON.stringify(data) }
    const { response } = yield call(post, requestURL, options)
    yield put(actions.estimateSubscriptionSuccess(response.estimate))
    if (action.callback) yield action.callback(response.estimate)
  } catch (err) {
    yield put(actions.estimateSubscriptionFailed(err))
    if (action.callback) yield action.callback(err)
  }
}

export function* watchEstimateSubscription() {
  yield takeLatest(types.ESTIMATE_SUBSCRIPTION, estimateSubscription)
}

export function* createStripePaymentIntent(action) {
  const { attributes } = action
  const paymentIntent = yield select(selectCurrentUserPaymentIntent())
  try {
    const data = {
      data: {
        type: "stripe_payment_intents",
        attributes,
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    let requestURL
    let response
    if (paymentIntent?.status === "requires_source") {
      requestURL = `${process.env.API_URL}/stripe_payment_intents/${paymentIntent.id}`
      response = yield call(patch, requestURL, options)
    } else {
      requestURL = `${process.env.API_URL}/stripe_payment_intents`
      response = yield call(post, requestURL, options)
    }
    yield put(actions.createOrUpdatePaymentIntentSuccess(response))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchCreateStripePaymentIntent() {
  yield takeLatest(
    types.CREATE_STRIPE_PAYMENT_INTENT,
    createStripePaymentIntent
  )
}

export function* createOrUpdatePaymentIntent(action) {
  const { attributes } = action
  try {
    const paymentIntent = yield select(selectCurrentUserPaymentIntent())
    const data = {
      data: {
        type: "payment_intents",
        attributes,
      },
    }
    const options = { data: JSON.stringify(data) }
    let requestURL
    let response
    if (
      paymentIntent &&
      !["requires_source", "authorized"].includes(paymentIntent?.status)
    ) {
      requestURL = `${process.env.API_URL}/payment_intents/${paymentIntent.id}`
      response = yield call(patch, requestURL, options)
    } else {
      requestURL = `${process.env.API_URL}/payment_intents`
      response = yield call(post, requestURL, options)
    }
    const paymentIntentResponse = response.response.payment_intent
    yield put(actions.createOrUpdatePaymentIntentSuccess(paymentIntentResponse))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchCreateOrUpdatePaymentIntent() {
  yield takeLatest(
    types.CREATE_OR_UPDATE_PAYMENT_INTENT,
    createOrUpdatePaymentIntent
  )
}

export function* paypalCheckoutStart(action) {
  try {
    const { amount, description, redirectPath } = action
    const requestURL = `${process.env.API_URL}/paypal_express_checkout/initiate_payment`
    const redirectUrl =
      process.env.BUILD_ENV === "development"
        ? `http://localhost:5000${redirectPath}`
        : `${process.env.BASE_APP_URL}${redirectPath}`
    const data = {
      data: {
        amount,
        cancel_url: redirectUrl,
        currency_code: "USD",
        description,
        return_url: redirectUrl,
      },
    }
    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)

    const paypalUrl = isProduction()
      ? `https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=${response.TOKEN}`
      : `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=${response.TOKEN}`

    window.location = paypalUrl
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchPaypalCheckoutStart() {
  yield takeLatest(types.PAYPAL_CHECKOUT_START, paypalCheckoutStart)
}

export function* syncSubscription(action) {
  try {
    const data = {
      data: {
        plan_id: action.plan_id,
        user_id: currentUserIdFromToken(),
      },
    }
    const options = {
      headers: defaultHeaders(),
      data: JSON.stringify(data),
    }

    const requestURL = `${
      process.env.API_URL
    }/users/${currentUserIdFromToken()}/sync_subscription`

    const response = yield call(post, requestURL, options)
    yield put(actions.requestSuccess(response))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchSyncSubscription() {
  yield takeLatest(types.SYNC_SUBSCRIPTION, syncSubscription)
}

export function* payDelinquentInvoices(action) {
  try {
    const data = {
      data: {
        plan_id: action.plan_id,
        user_id: currentUserIdFromToken(),
      },
    }
    const options = {
      headers: defaultHeaders(),
      data: JSON.stringify(data),
    }

    const requestURL = `${
      process.env.API_URL
    }/users/${currentUserIdFromToken()}/pay_delinquent_invoices`

    const response = yield call(post, requestURL, options)
    yield put(actions.requestSuccess(response))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchPayDelinquentInvoices() {
  yield takeLatest(types.PAY_DELINQUENT_INVOICES, payDelinquentInvoices)
}

export function* getReferrals() {
  try {
    const options = {
      headers: defaultHeaders(),
    }
    const requestURL = `${process.env.API_URL}/referrals`
    const response = yield call(get, requestURL, options)
    yield put(actions.getReferralsSuccess(response))
  } catch (err) {
    yield put(actions.requestFailed())
    yield put(actions.getReferralsError(err))
    yield put(uiActions.setError(err))
  }
}

export function* watchGetReferrals() {
  yield takeLatest(types.GET_REFERRALS, getReferrals)
}

export function* identifyLoggedInUserOnInit() {
  if (loggedIn() && !authTokenExpired()) {
    yield take(types.REQUEST_SUCCESS)
    yield put(trackingActions.identifyCurrentUser())
  }
}

export default [
  identifyLoggedInUserOnInit,
  watchCancelSubscription,
  watchChangePasswordSubmit,
  watchCheckoutFormSubmit,
  watchContactLicensingSubmit,
  watchPaymentRequestButtonSubmit,
  watchClearAvatar,
  watchCreateOrUpdatePaymentIntent,
  watchCreateStripePaymentIntent,
  watchEnterpriseSignUp,
  watchEnterpriseModalSignUp,
  watchEstimateSubscription,
  watchForgotPassword,
  watchLoad,
  watchPayDelinquentInvoices,
  watchPaypalCheckoutStart,
  watchPreviewUpcomingInvoiceSubmit,
  watchProsperstackCancelSubscription,
  watchReactivateSubmit,
  watchRetrievePaymentSource,
  watchRetrieveSubscriptionWithScheduledChanges,
  watchSignIn,
  watchSignInSuccess,
  watchSignUp,
  watchGoogleOauthSubmit,
  watchSignOutEverywhereSubmit,
  watchSyncSubscription,
  watchTeamSeatModalSubmit,
  watchTrackUpsell,
  watchTrackUpsellAttempt,
  watchUnlinkOauthAccounts,
  watchUpdate,
  watchUpdateAvatar,
  watchupdateBillingInfoSubmit,
  watchUpdateProfileSubmit,
  watchUpsellSubmit,
  watchContactSfxLicensingSubmit,
  watchCartCheckoutFormSubmit,
]
